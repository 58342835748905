import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import ContractsDataTableRows from './ContractsDataTableRows';
import ContractsDataTableHeader from './ContractsDataTableHeader';
import StyledContractsDataTable from './styled/StyledContractsDataTable';

const Caption = styled.caption`
  caption-side: top;
  text-align: center;
  color: ${prop('theme.colors.black')};
  font-weight: ${prop('theme.fontWeights.bold')};
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-bottom: none;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.813rem 0 0.688rem 0;
`;

const propTypes = {
  symbol: PropTypes.string,
  columns: PropTypes.object,
  head: PropTypes.object,
  rows: PropTypes.array
};

const defaultProps = {
  symbol: '',
  columns: {},
  head: {},
  rows: []
};

// Created this component and its children as a result of gatsby-prismic-plugin migration.
// Completely different data format for columns and headers on tables in the standard page
// as a result of the migration, and much easier tweaking existing components than
// messing with the historical contracts API response.
function ContractsDataTable({ symbol, columns, head, rows, selectedDate }) {
  const headerKeys = Object.keys(head);

  return (
    <StyledContractsDataTable>
      <Caption>{symbol}</Caption>
      <ContractsDataTableHeader
        columns={columns}
        head={head}
        headerKeys={headerKeys}
      />
      <tbody>
        <ContractsDataTableRows
          columns={columns}
          head={head}
          headerKeys={headerKeys}
          rows={rows}
          selectedDate={selectedDate}
        />
      </tbody>
    </StyledContractsDataTable>
  );
}

ContractsDataTable.propTypes = propTypes;
ContractsDataTable.defaultProps = defaultProps;

export default ContractsDataTable;
