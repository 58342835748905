import React from 'react';
import map from 'lodash-es/map';
import isEmpty from 'lodash-es/isEmpty';
import first from 'lodash-es/first';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import HeaderContent from '../../TableBlock/HeaderContent';
import { getTableTooltips } from 'utils/functions';

export const StyledThead = styled.thead`
  height: 3rem;
  position: relative;
  display: none;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: table-header-group;
  }
`;

const StyledTh = styled.th`
  border-top: 1px solid ${prop('theme.colors.borderGrey')};
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
  font-weight: ${prop('theme.fontWeights.medium')};
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 1.5rem;
  padding: 0 1.25rem;
  text-align: right;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    padding: 0;
  }

  &:first-child {
    text-align: left;
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
  }

  &:last-child {
    border-right: 1px solid ${prop('theme.colors.borderGrey')};
  }
`;

const propTypes = {
  columns: PropTypes.object,
  head: PropTypes.object,
  headerKeys: PropTypes.array
};

const defaultProps = {
  columns: {},
  head: {},
  headerKeys: []
};

function ContractsDataTableHeader({ columns, head, headerKeys }) {
  let mappedColumns = map(columns, function(column, index) {
    let triggerValue = 'column-' + Math.floor(Math.random() * 200);
    let tableTooltips = getTableTooltips(column, head, headerKeys);

    // Making sure there are column headers.
    const arrayColumnCheck =
      column && first(column).text && !isEmpty(first(column).text)
        ? true
        : false;
    const stringColumnCheck =
      typeof column === 'string' && column.length > 0 ? true : false;

    if (arrayColumnCheck || stringColumnCheck) {
      return (
        <StyledTh
          key={index}
          withtooltip={
            tableTooltips && tableTooltips.length > 0 ? 'true' : 'false'
          }
        >
          <HeaderContent
            column={column}
            tableTooltips={tableTooltips}
            triggerValue={triggerValue}
          />
        </StyledTh>
      );
    }
  });

  return (
    <StyledThead>
      <tr>{mappedColumns}</tr>
    </StyledThead>
  );
}

ContractsDataTableHeader.propTypes = propTypes;
ContractsDataTableHeader.defaultProps = defaultProps;

export default ContractsDataTableHeader;
