import { prop } from 'styled-tools';
import styled from 'styled-components';
import StyledTd from '../../TableBlock/styled/StyledTd';
import StyledTh from '../../TableBlock/styled/StyledTh';
import StyledTable from '../../TableBlock/styled/StyledTable';

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    overflow-x: auto;
  }

  ${StyledTable} {
    tr {
      display: table-row;
      border: none;
    }

    ${StyledTh} {
      font-size: 0.875rem;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        padding: 0.875rem;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        font-size: 1rem;
        padding: 0.875rem 1rem;
      }
    }

    ${StyledTd} {
      font-size: 0.875rem;
      padding: 0.625rem 0.875rem;
      text-align: right;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        padding: 0.875rem;
        text-align: left;
        vertical-align: top;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        font-size: 1rem;
        padding: 0.875rem 1rem;
      }

      > * {
        font-size: 0.875rem;
        line-height: inherit;
        width: 50%;

        @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
          width: auto;
        }

        @media (min-width: ${prop('theme.breakpoints.xl')}) {
          font-size: 1rem;
        }
      }
    }
  }
`;

export default DataContainer;
