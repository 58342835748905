import React, { useContext, useState } from 'react';
import map from 'lodash-es/map';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

import HeaderContent from '../../TableBlock/HeaderContent';
import { getTableTooltips } from 'utils/functions';
import RichTextWithDictionary from 'components/RichTextWithDictionary';
import DateContext from '../../../context/DateContext';
import dayjs from '../../../utils/dayjs';

export const HeaderWrapper = styled.span`
  display: flex;
  text-align: left;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: none;
  }
`;

const StyledTr = styled.tr`
  cursor: pointer;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    border: 1px solid ${prop('theme.colors.borderGrey')};
    border-top: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
`;

const StyledTd = styled.td`
  display: flex;
  padding: 0.5rem 0.125rem;
  font-size: 0.875rem;
  justify-content: space-between;

  &:first-child {
    text-align: left;
    font-weight: ${prop('theme.fontWeights.semiBold')};

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      ${ifProp(
        { $dateDifference: 0 },
        css`
          border-left: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
        `,
        css`
          border-left: 1px solid ${prop('theme.colors.borderGrey')};
        `
      )}
    }
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    ${switchProp(
      '$dateDifference',
      {
        '1': css`
          border-bottom: none;
        `,
        '0': css`
          margin-top: -1px;
          border-top: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
          border-bottom: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
        `
      },
      css`
        border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
      `
    )}

    &:last-child {
      ${ifProp(
        { $dateDifference: 0 },
        css`
          border-right: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
        `,
        css`
          border-right: 1px solid ${prop('theme.colors.borderGrey')};
        `
      )}
    }
  }

  @media (min-width: ${prop('theme.breakpoints.xs')}) {
    padding: 0.5rem 0.25rem;
    text-align: right;
  }

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding: 0.625rem 0.875rem;
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding: 0.875rem 1.25rem;
    display: table-cell;
  }
`;

const propTypes = {
  columns: PropTypes.object,
  head: PropTypes.object,
  headerKeys: PropTypes.array,
  rows: PropTypes.array
};

const defaultProps = {
  columns: {},
  head: {},
  headerKeys: [],
  rows: []
};

function ContractsDataTableRows({ columns, head, headerKeys, rows }) {
  const { selectedDate, setSelectedDate } = useContext(DateContext);
  const startingIndex = rows.findIndex((row) => row.col1_data === selectedDate);
  const [selectedIndex, setSelectedIndex] = useState(
    startingIndex > -1 ? startingIndex : 0
  );

  return map(rows, function(row, index) {
    let rowDate = '';
    let values = map(columns, function(column, columnKey) {
      let triggerValue = 'value-' + Math.floor(Math.random() * 200);
      let matchColumnKey = columnKey + '_data';
      let columnName =
        column && first(column).text
          ? !isEmpty(first(column).text)
          : typeof column === 'string'
          ? column
          : null;
      let columnValue = first(row[matchColumnKey]);
      let tableTooltips = getTableTooltips(column, head, headerKeys);
      let cellArrayRich = [];
      let cellArrayRegular = [];

      if (columnName) {
        if (typeof columnValue === 'object') {
          cellArrayRich.push(columnValue);
        } else {
          cellArrayRegular.push(row[matchColumnKey]);
        }

        if (columnName === 'Date') {
          rowDate = cellArrayRegular[0];
        }
        const dateDifference = selectedIndex - index;

        return (
          <StyledTd
            key={`tr_${index}_td_${matchColumnKey}`}
            $dateDifference={dateDifference}
            withtooltip={
              tableTooltips && tableTooltips.length > 0 ? 'true' : 'false'
            }
            withRichText={cellArrayRich.length > 0 ? 'true' : 'false'}
          >
            <HeaderWrapper
              withRichText={cellArrayRich.length > 0 ? 'true' : 'false'}
            >
              <HeaderContent
                column={column}
                tableTooltips={tableTooltips}
                triggerValue={triggerValue}
              />
            </HeaderWrapper>
            {cellArrayRich.length > 0 ? (
              <RichTextWithDictionary render={cellArrayRich} />
            ) : (
              <span>{cellArrayRegular}</span>
            )}
          </StyledTd>
        );
      }
    });

    const selectRow = () => {
      setSelectedDate(rowDate);
      setSelectedIndex(index);
    };
    return (
      <StyledTr
        onClick={selectRow}
        className={rowDate === selectedDate && 'selected'}
        key={`row-${index}`}
      >
        {values}
      </StyledTr>
    );
  });
}

ContractsDataTableRows.propTypes = propTypes;
ContractsDataTableRows.defaultProps = defaultProps;

export default ContractsDataTableRows;
