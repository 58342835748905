import { useState, useEffect } from 'react';
import flatten from 'lodash-es/flatten';

function useFetchContractData(symbols, fromDate, toDate) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('symbols', symbols);
      params.append('fromDate', fromDate);
      params.append('toDate', toDate);

      const res = await fetch(
        `${process.env.GATSBY_DX_FEED_PROXY_URL ||
          process.env.DX_FEED_PROXY_URL}/api/contract-data?${params.toString()}`
      );

      res
        .json()
        .then((res) => {
          // dxfeed returns an array of arrays instead of
          // something better like an object with the symbols as keys :(
          const flattenedRes = flatten(res);
          setData(flattenedRes);
          setLoading(false);
        })
        .catch((err) => {
          setData(null);
          setLoading(false);
        });
    };

    fetchData();
  }, [symbols, fromDate, toDate]);

  return [data, loading];
}

export default useFetchContractData;
