import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import orderBy from 'lodash-es/orderBy';
import isEmpty from 'lodash-es/isEmpty';

import DataContainer from './styled/DataContainer';
import dayjs from 'utils/dayjs';
import ContractsDataTable from './ContractsDataTable/index';
import {
  checkForNumValue,
  findContractMonth,
  formatDecimals,
  trimFuturesSuffix
} from 'utils/functions';
import DateContext from '../../context/DateContext';

const ErrorMessage = styled.div`
  margin-bottom: 0;
  padding: 1rem;
  text-align: center;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem;
`;

const propTypes = {
  contractData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedTimeframe: PropTypes.object
};

const defaultProps = {
  contractData: {},
  selectedTimeframe: {}
};

const ContractsData = ({ contractData, selectedTimeframe }) => {
  const headerObject = {
    col1: 'Date',
    col2: 'Last',
    col3: 'Open',
    col4: 'Close',
    col5: 'High',
    col6: 'Low',
    col7: 'Volume',
    col8: 'Open Int.'
  };
  const { selectedDate, setSelectedDate } = useContext(DateContext);
  const endOfTodaysTradingDay = dayjs()
    .tz()
    .hour(16)
    .minute(15);
  const rightNow = dayjs().tz();
  let symbol = '';

  function formatContractData(contractData) {
    if (!contractData) {
      return;
    }

    const handleDecimal = (value) => {
      return formatDecimals(checkForNumValue(value, 'n/a')).toLocaleString();
    };

    const filteredContractData = contractData.filter((value) => {
      const expirationMonth = findContractMonth(value.eventSymbol);
      const trimmedSymbol = trimFuturesSuffix(value.eventSymbol);
      const expirationYear = trimmedSymbol.substr(trimmedSymbol.length - 2);

      return (
        expirationMonth === selectedTimeframe.month.label &&
        expirationYear === selectedTimeframe.year.label.substr(2)
      );
    });

    if (!isEmpty(filteredContractData)) {
      // after filtering, we know we will have the correct symbol in this array
      symbol = trimFuturesSuffix(filteredContractData[0].eventSymbol);
    }

    const body = filteredContractData
      .map((dataValue) => ({
        col1_data: dayjs(dataValue.time)
          .utc()
          .format('MM/DD/YYYY'),
        col2_data: handleDecimal(dataValue.last, 'n/a'),
        col3_data: handleDecimal(dataValue.open, 'n/a'),
        col4_data: handleDecimal(dataValue.close, 'n/a'),
        col5_data: handleDecimal(dataValue.high, 'n/a'),
        col6_data: handleDecimal(dataValue.low, 'n/a'),
        col7_data: checkForNumValue(dataValue.volume).toLocaleString(),
        col8_data: checkForNumValue(dataValue.openInterest).toLocaleString()
      }))
      .filter(
        (bodyObject) =>
          bodyObject.col1_data !== rightNow.format('MM/DD/YYYY') ||
          rightNow.isAfter(endOfTodaysTradingDay)
      );

    return body;
  }
  const formattedContractData = formatContractData(contractData);

  const sortedBody = orderBy(
    formattedContractData,
    [
      function(data) {
        return Date.parse(data.col1_data);
      }
    ],
    ['desc']
  );

  if (!contractData) {
    return (
      <ErrorMessage>
        Sorry, there was an issue retrieving the contracts data!
      </ErrorMessage>
    );
  }

  if (sortedBody.length === 0) {
    return (
      <ErrorMessage>
        No data for the filter parameters selected. Check your filter
        parameters.
      </ErrorMessage>
    );
  }

  // when the user changes to a new month or year, it means we should reset the selected date to the first one available
  // if the user clicked one of the select elements but did not actually change the month or year, do not change the selected date
  useEffect(() => {
    if (!sortedBody.some((element) => element.col1_data === selectedDate)) {
      setSelectedDate(sortedBody[0].col1_data);
    }
  }, [selectedTimeframe]);

  return (
    <DataContainer>
      <ContractsDataTable
        symbol={symbol}
        columns={headerObject}
        head={headerObject}
        rows={sortedBody}
      />
    </DataContainer>
  );
};

ContractsData.propTypes = propTypes;
ContractsData.defaultProps = defaultProps;

export default ContractsData;
