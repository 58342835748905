import { prop } from 'styled-tools';
import styled from 'styled-components';

const StyledContractsDataTable = styled.table`
  background-color: ${prop('theme.colors.white')};
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  .selected {
    background-color: ${prop('theme.colors.yellowHighlight')};
  }

  tbody {
    @media (max-width: ${prop('theme.breakpoints.md')}) {
      border-top: 1px solid ${prop('theme.colors.borderGrey')};
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1 0 100%;
    }
  }
`;

export default StyledContractsDataTable;
