import React, { useContext, useState } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import dayjs from 'utils/dayjs';
import ContractsData from '../../ContractsData';
import ContractsHeader from '../../ContractsData/ContractsHeader';
import SpinnerContainer from '../../SpinnerContainer';
import useFetchContractData from '../../../hooks/fetchContractData';
import {
  monthAndYearSelectOptions,
  findContractExpirationDate,
  findFrontBackMonth,
  historicalContractsYears,
  getAllMonthSymbols
} from '../../../utils/functions';
import { FUTURES_START_DATE_FORMATTED } from '../../../utils/constants';
import DateContext from '../../../context/DateContext';
import BlockStyles from '../../BlockStyles';

const BorderContainer = styled.div`
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 8px;
`;

const TableContainer = styled.div`
  margin: 0 24px 40px 24px;
  position: relative;
`;

const propTypes = {
  symbol: PropTypes.string,
  template: PropTypes.string,
  primary: PropTypes.object
};

const defaultProps = {
  symbol: '',
  template: '',
  primary: {}
};

function FuturesContractTable({ template, symbol, primary }) {
  const { selectedTimeframe, setSelectedTimeframe, endDate } = useContext(
    DateContext
  );

  const handleTimeframeChange = (option, props) => {
    setSelectedTimeframe({
      ...selectedTimeframe,
      [props.name]: {
        value: option.value,
        label: option.label
      }
    });
  };

  // Defining variables for dropdowns.
  const today = dayjs();
  const expDate = findContractExpirationDate(today, symbol);
  const [, backMonth] = findFrontBackMonth(expDate);
  const years = historicalContractsYears(today);
  const initialDate = dayjs(FUTURES_START_DATE_FORMATTED);
  const endYear = endDate ? dayjs(endDate).year() : +backMonth.split(' ')[1];
  const options = monthAndYearSelectOptions(endYear);

  // Setup for fetching historical contracts data.
  const fromDate = initialDate.format('YYYYMMDD');
  // We add 1 day into the future here so that we make sure we get
  // THO (trading hours only) events that are midday, since dxFeed API considers
  // the toDate to have a time cutoff of 00:00:00 when sending only a date.
  const toDate = dayjs()
    .add(1, 'day')
    .format('YYYYMMDD');

  const futuresSymbols = getAllMonthSymbols(symbol, backMonth, years);
  const [contractData, loading] = useFetchContractData(
    futuresSymbols,
    fromDate,
    toDate
  );

  const tooltipTitle = primary.contract_tooltip_title;
  return (
    <BlockStyles template={template}>
      <TableContainer>
        <ContractsHeader
          title={primary.contract_title.richText}
          description={primary.contract_description.richText}
          tooltip={primary.contract_tooltip}
          tooltipTitle={tooltipTitle && tooltipTitle.text}
          monthOptions={options.months}
          yearOptions={options.years}
          selectedTimeframe={selectedTimeframe}
          handleTimeframeChange={handleTimeframeChange}
        />
        {loading ? (
          <BorderContainer>
            <SpinnerContainer height='500'>
              <Spinner size='lg' />
            </SpinnerContainer>
          </BorderContainer>
        ) : (
          <ContractsData
            contractData={contractData}
            selectedTimeframe={selectedTimeframe}
          />
        )}
      </TableContainer>
    </BlockStyles>
  );
}

FuturesContractTable.propTypes = propTypes;
FuturesContractTable.defaultProps = defaultProps;

export default FuturesContractTable;
