import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import RichTextWithDictionary from 'components/RichTextWithDictionary';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  h1 {
    color: ${prop('theme.colors.redPrimary')};
    font-size: 1.125rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    line-height: 2rem;
    margin-bottom: 0;
  }

  div {
    font-size: 0.875rem;
  }
`;

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.array
};

const defaultProps = {
  title: [],
  description: [],
  tooltipTitle: '',
  tooltip: []
};

function ContractsTitle({ title, description, tooltipTitle, tooltip }) {
  const descriptionText =
    description && description[0] ? description[0].text : null;

  return (
    <>
      <Container>
        <TitleContainer>
          <RichTextWithDictionary render={title} />
          <div>{descriptionText}</div>
        </TitleContainer>
      </Container>
    </>
  );
}

ContractsTitle.defaultProps = defaultProps;

export default ContractsTitle;
