import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import ContractsTitle from './ContractsTitle';
import dayjs from 'utils/dayjs';
import Tooltip from '../Tooltip';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 2rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    align-items: center;
    flex-direction: row;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;

  a {
    margin: 0;
  }
  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    position: absolute;
    top: 24px;
    right: 0;
    width: 65%;
    justify-content: flex-end;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    top: 4rem;
  }

  .filter-dropdown {
    font-size: 0.875rem;
    margin-left: 1rem;
    max-width: 12.5rem;
    width: 50%;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      width: 100%;
    }

    &:first-child {
      margin: 0;
    }

    > * {
      cursor: pointer;
    }
  }
`;

const TooltipContainer = styled.div`
  margin-left: 0.75rem;
`;

const propTypes = {
  title: PropTypes.array,
  description: PropTypes.array,
  tooltip: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tooltipTitle: PropTypes.string,
  monthOptions: PropTypes.array,
  yearOptions: PropTypes.array,
  selectedTimeframe: PropTypes.object,
  handleTimeframeChange: PropTypes.func
};

const defaultProps = {
  title: [],
  description: [],
  tooltip: [],
  tooltipTitle: '',
  monthOptions: [],
  yearOptions: [],
  selectedTimeframe: null,
  handleTimeframeChange: null
};

function ContractsHeader({
  title,
  description,
  tooltip,
  tooltipTitle,
  monthOptions,
  yearOptions,
  selectedTimeframe,
  handleTimeframeChange
}) {
  const { month, year } = selectedTimeframe;
  const titleText = title ? title[0].text : null;

  return (
    <>
      <SelectContainer>
        <Select
          className='filter-dropdown'
          onChange={handleTimeframeChange}
          options={monthOptions}
          placeholder='Month'
          value={month.value && month}
          name='month'
          defaultValue={dayjs().month()}
        />
        <Select
          className='filter-dropdown'
          onChange={handleTimeframeChange}
          options={yearOptions}
          placeholder='Year'
          value={year.value && year}
          name='year'
        />
        {tooltip && (
          <TooltipContainer>
            <Tooltip
              title={tooltipTitle}
              content={tooltip}
              place='bottom'
              triggerValue={titleText}
            />
          </TooltipContainer>
        )}
      </SelectContainer>
      <HeaderContainer>
        <ContractsTitle title={title} description={description} />
      </HeaderContainer>
    </>
  );
}

ContractsHeader.propTypes = propTypes;
ContractsHeader.defaultProps = defaultProps;

export default ContractsHeader;
